<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <div class="tit-wrap text-center">
                <h2 class="tit">Contact Us</h2>
            </div>
            <div class="tit mb-20px mb-lg-30px">
                <v-row justify="center" align="center" class="row--xs">
                    <v-col cols="auto" class="font-weight-light">입점 신청서 작성</v-col>
                    <v-col cols="auto">
                        <v-card width="26" height="26" color="grey" outlined class="d-flex justify-center align-center">
                            <v-icon small class="grey--text">mdi-chevron-right</v-icon>
                        </v-card>
                    </v-col>
                    <v-col cols="auto" class="font-weight-light">담당자 검토</v-col>
                    <v-col cols="auto">
                        <v-card width="26" height="26" color="grey" outlined class="d-flex justify-center align-center">
                            <v-icon small class="grey--text">mdi-chevron-right</v-icon>
                        </v-card>
                    </v-col>
                    <v-col cols="auto" class="font-weight-light">계약체결</v-col>
                </v-row>
            </div>

            <v-card tile color="grey lighten-5" class="mb-20px mb-lg-30px pa-20px px-lg-50px py-lg-30px">
                <p class="txt line-height-17">
                    모아고에 브랜드 입점, 제휴 마케팅 및 대량 구매 문의 등을 원하실 경우, 각 항목에 맞게 작성 부탁드립니다. <br />
                    1. 입점 신청서 작성<br />
                    2. 담당자 검토 및 연락 (담당자가 검토 후에 입점이 적합한 경우 전화나 이메일로 연락을 드리겠습니다.)<br />
                    3. 조건 협의 및 계약체결 (제휴 내용 구체화 및 조건을 협의한 후 계약을 체결합니다.)<br />
                </p>
            </v-card>
            <contact-input code="contact" skin="contact" />
        </page-section>
    </client-page>
</template>

<script>
import { btn_icon } from "@/assets/variables";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ContactInput from "@/components/client/form/contact-input.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ContactInput,
    },
    data: () => ({
        btn_icon,
    }),
    computed: {},
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-next {
    display: inline-block;
    max-width: 20px;
    margin: 0 6px;
    transform: translateY(2px);
}
@media (min-width: 768px) {
    .icon-next {
        display: inline-block;
        max-width: 26px;
        margin: 0 10px;
        transform: initial;
    }
}
@media (min-width: 1024px) {
    .icon-next {
        margin: 0 20px;
    }
}
</style>
