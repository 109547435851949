var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("Contact Us")])]), _c('div', {
    staticClass: "tit mb-20px mb-lg-30px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("입점 신청서 작성")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "width": "26",
      "height": "26",
      "color": "grey",
      "outlined": ""
    }
  }, [_c('v-icon', {
    staticClass: "grey--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("담당자 검토")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "width": "26",
      "height": "26",
      "color": "grey",
      "outlined": ""
    }
  }, [_c('v-icon', {
    staticClass: "grey--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("계약체결")])], 1)], 1), _c('v-card', {
    staticClass: "mb-20px mb-lg-30px pa-20px px-lg-50px py-lg-30px",
    attrs: {
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('p', {
    staticClass: "txt line-height-17"
  }, [_vm._v(" 모아고에 브랜드 입점, 제휴 마케팅 및 대량 구매 문의 등을 원하실 경우, 각 항목에 맞게 작성 부탁드립니다. "), _c('br'), _vm._v(" 1. 입점 신청서 작성"), _c('br'), _vm._v(" 2. 담당자 검토 및 연락 (담당자가 검토 후에 입점이 적합한 경우 전화나 이메일로 연락을 드리겠습니다.)"), _c('br'), _vm._v(" 3. 조건 협의 및 계약체결 (제휴 내용 구체화 및 조건을 협의한 후 계약을 체결합니다.)"), _c('br')])]), _c('contact-input', {
    attrs: {
      "code": "contact",
      "skin": "contact"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }