<template>
    <client-page>
        <page-section class="page-section--first page-section--last" containerSize="container--lg">
            <sub-business-tabs />

            <v-row no-gutters align="center">
                <v-col cols="12" lg="6"><v-img src="/images/sub/business/guide/guide-01.jpg" /></v-col>
                <v-col cols="12" lg="6">
                    <div class="guide-txt">
                        <h3 class="tit font-weight-light mb-4px mb-lg-6px">회원가입 안내</h3>
                        <p class="txt txt--dark line-height-2">
                            회원가입은 무료이며, 회원가입 즉시 회원으로 사이트 이용이 가능합니다.<br class="d-none d-xl-block" />
                            회원을 가입하시면 구매 시 마일리지가 지급되며, 회원할인, 쿠폰지급 등 <br class="d-none d-xl-block" />
                            다양한 혜택을 드립니다.또한 주문할때마다 배송지를 입력하실 필요없이 <br class="d-none d-xl-block" />
                            빠르고 편하게 주문할 수 있습니다.
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters align="center">
                <v-col cols="12" lg="6" order-lg="2"><v-img src="/images/sub/business/guide/guide-02.jpg" /></v-col>
                <v-col cols="12" lg="6">
                    <div class="guide-txt">
                        <h3 class="tit font-weight-light mb-4px mb-lg-6px">주문 및 결제</h3>
                        <p class="txt txt--dark line-height-2">
                            상품을 여러 개 장바구니에 담으셔서 한번에 주문하실 수도 있으며 <br class="d-none d-xl-block" />
                            옵션별로 주문이 가능합니다.결제는 신용카드, 계좌이체, 무통장입금 등 <br class="d-none d-xl-block" />
                            다양한 방법으로 상품을 주문할 수 있습니다.
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters align="center">
                <v-col cols="12" lg="6"><v-img src="/images/sub/business/guide/guide-03.jpg" /></v-col>
                <v-col cols="12" lg="6">
                    <div class="guide-txt">
                        <h3 class="tit font-weight-light mb-4px mb-lg-6px">배송안내</h3>
                        <p class="txt txt--dark line-height-2">
                            MOAGO는 여러 미니샵들이 모여 만들어진 판매 커뮤니티 플렛폼 입니다. <br class="d-none d-xl-block" />
                            따라서 배송정책은 미니샵마다 다른 정책을 가지고 시행하고 있으니, <br class="d-none d-xl-block" />
                            구매한 미니샵에서 정책을 확인하셔야 합니다. <br class="d-none d-xl-block" />
                            배송위치는 마이페이지에서 추적하실 수 있습니다.
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters align="center">
                <v-col cols="12" lg="6" order-lg="2"><v-img src="/images/sub/business/guide/guide-04.jpg" /></v-col>
                <v-col cols="12" lg="6">
                    <div class="guide-txt">
                        <h3 class="tit font-weight-light mb-4px mb-lg-6px">환불 및 반품</h3>
                        <p class="txt txt--dark line-height-2">
                            물품의 환불 및 반품은 대한민국 소비자 법에 근거하여 시행합니다. <br class="d-none d-xl-block" />
                            물품에 손상이 있거나, 개봉 후 사용하여 <br class="d-none d-xl-block" />
                            새 상품으로 판매하기 어려운 경우에는 환불 및 반품이 불가능합니다. <br class="d-none d-xl-block" />
                            판매자가 본사가 아닌 입점사인 경우 입점사의 규정에 따라 진행되오니 <br class="d-none d-xl-block" />
                            구매 전 꼭확인하시기 바랍니다.
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters align="center">
                <v-col cols="12" lg="6"><v-img src="/images/sub/business/guide/guide-05.jpg" /></v-col>
                <v-col cols="12" lg="6">
                    <div class="guide-txt">
                        <h3 class="tit font-weight-light mb-4px mb-lg-6px">STORY 이용</h3>
                        <p class="txt txt--dark line-height-2">
                            STORY 게시판은 다양한 사람들과 소통이 가능한 커뮤니티 게시판입니다. <br class="d-none d-xl-block" />
                            자전거 라이딩 후기, 제품 후기, 잡답 등 편하게 자신의 일상을 공유하면서 <br class="d-none d-xl-block" />
                            라이딩 생활이 더 즐거워지도록 하는 역할을 합니다. <br class="d-none d-xl-block" />
                            상품태그 기능을 통해 공유하고 싶은 상품도 다양하게 공유가능합니다. <br class="d-none d-xl-block" />
                            다만 커뮤니티 게시판의 특성상 폭언,욕설,음담패설 등 상대방을 존중하지 않는 행위는 신고 및 처벌의 대상이 될 수 있으니서로간의 존중을 지켜주시기 바랍니다.
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters align="center">
                <v-col cols="12" lg="6" order-lg="2"><v-img src="/images/sub/business/guide/guide-06.jpg" /></v-col>
                <v-col cols="12" lg="6">
                    <div class="guide-txt">
                        <h3 class="tit font-weight-light mb-4px mb-lg-6px">미니홈</h3>
                        <p class="txt txt--dark line-height-2">
                            자기만의 특별한 미니홈을 꾸밀 수 있습니다. <br class="d-none d-xl-block" />
                            FOLLOW기능을 활용하여 자주 방문하는 미니홈을 자유롭게 방문할 수 있습니다.
                        </p>
                    </div>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import SubBusinessTabs from "@/components/client/sub/business/sub-business-tabs.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        SubBusinessTabs,
    },
};
</script>

<style lang="scss" scoped>
.guide-txt {
    padding: 24px 0 50px;
}
@media (min-width: 1024px) {
    .guide-txt {
        padding: 0 60px;
    }
}
@media (min-width: 1300px) {
    .guide-txt {
        padding: 0 80px;
    }
}
</style>
