<template>
    <client-page>
        <page-section class="page-section--first page-section--last" containerSize="container--lg">
            <sub-business-tabs />

            <div class="tit-wrap text-center">
                <h2 class="tit">이용약관</h2>
            </div>
            <v-sheet class="terms-wrap" color="grey lighten-5">
                <terms-component code="terms"></terms-component>
            </v-sheet>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import SubBusinessTabs from "@/components/client/sub/business/sub-business-tabs.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        SubBusinessTabs,
        TermsComponent,
    },
};
</script>
