var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('sub-business-tabs'), _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("이용약관")])]), _c('v-sheet', {
    staticClass: "terms-wrap",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('terms-component', {
    attrs: {
      "code": "terms"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }