var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    staticClass: "mb-40px mb-lg-60px",
    attrs: {
      "centered": "",
      "grow": ""
    }
  }, [_c('tab-primary', {
    attrs: {
      "to": "/sub/business/terms"
    }
  }, [_vm._v("이용약관")]), _c('tab-primary', {
    attrs: {
      "to": "/sub/business/privacy-policy"
    }
  }, [_vm._v("개인정보처리방침")]), _c('tab-primary', {
    attrs: {
      "to": "/sub/business/site-guide"
    }
  }, [_vm._v("이용안내")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }