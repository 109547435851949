<template>
    <v-tabs centered grow class="mb-40px mb-lg-60px">
        <tab-primary to="/sub/business/terms">이용약관</tab-primary>
        <tab-primary to="/sub/business/privacy-policy">개인정보처리방침</tab-primary>
        <tab-primary to="/sub/business/site-guide">이용안내</tab-primary>
    </v-tabs>
</template>

<script>
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";

export default {
    components: {
        TabPrimary,
    },
}
</script>

<style lang="scss" scoped></style>