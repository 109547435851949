<template>
    <component :is="SkinComponent" v-model="form" v-bind="{ code, loading }" v-on="{ save }" />
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { initCenterContacts } from "@/assets/variables";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
        category: { type: String, default: null },
    },

    data: () => ({
        form: initCenterContacts(),

        loading: false,
    }),

    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
    },
    methods: {
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if(!this.validates()) return;

                await api.v1.center.contacts.post(this.form);

                alert("성공적으로 문의가 접수되었습니다.");

                this.$router.go(-1);
            } finally {
                this.loading = false;
            }
        },
        validates(){
            if(!this.form.company) throw new Error("업체명을 입력해주세요.");
            if(!this.form.homepage) throw new Error("홈페이지 주소를 입력해주세요.");
            if(!this.form.writer.name) throw new Error("담당자 명을 입력해주세요.");
            if(!this.form.writer.phone) throw new Error("휴대폰 번호를 입력해주세요.");
            if(!this.form.content) throw new Error("내용을 입력해주세요.");

            return true;
        }
    },
};
</script>
